import React from 'react';
import './style.scoped.scss';
import HomeIcon from '@mui/icons-material/Home';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import InfoIcon from '@mui/icons-material/Info';
import ArticleIcon from '@mui/icons-material/Article';

import { Link } from 'react-router-dom';


//import { Button } from '@mui/material';

function Footer() {

    return (
        <div className="v-footer">

            <div className='wave-container'>
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(76,76,168,0.7)" />
                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(100, 81, 216, 0.6)" />
                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(57, 29, 222, 0.7)" />
                    <use className="backgroundwave" xlinkHref="#gentle-wave" x="48" y="7" fill="#0b0b11" />
                </g>
            </svg>
            </div>
            
            <div className="inner-header flex">
                <div className="content-container">
                    <div className="nav">
                        <div className="column1">
                            <Link to="/" className="link" ><HomeIcon/>Home</Link>
                            <Link to="/standards" className="link" ><AnalyticsIcon/>Standards</Link>
                            <Link to="/handbook" className="link" ><FormatAlignCenterIcon/>Rules</Link>
                        </div>
                        <div className="column1">
                            <a href="https://sypher-project.com" className="link" ><ArticleIcon/>Sypher</a>
                            <a href="https://sypher-project.com/about" className="link" ><InfoIcon/>About</a>
                        </div>
                    </div>
                    <div className="opt-text">
                    </div>
                </div>
            <div className="inner-text"></div>
            </div>
        </div>
    );
}

export default Footer;