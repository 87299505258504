import React from 'react';
import { Button } from '@mui/material';
import './style.scoped.scss';

//import Navigation from '../../components/Navigation/Navigation';

import { useRef, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import background from '../../assets/landingbg.png';
import car from '../../assets/dominusredcar.png';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
//import carillustration1 from '../../assets/car-illustration1.png';
//import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';

/*
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import ApiIcon from '@mui/icons-material/Api';
import HandshakeIcon from '@mui/icons-material/Handshake';

import illustration1 from '../../assets/illustration1.png';
import illustration2 from '../../assets/illustration2.png';
import illustration3 from '../../assets/illustration3.png';

import carillustration2 from '../../assets/car-illustration2.png';
import discordDev from '../../assets/discord-dev.png';
import mediaIllustration from '../../assets/media-illustration-purple2.png';

import BlobAnimation from '../../components/BlobAnimation/BlobAnimation';
import ProgramBlob from '../../components/BlobAnimation/ProgramBlob';

import BackgroundWaves from '../../components/BackgroundWaves/BackgroundWaves';

import StripWave from '../../components/BackgroundWaves/StripWave';
import { Link } from 'react-router-dom';
*/
import ProgramWave from '../../components/BackgroundWaves/ProgramWave';


function Landing() {
    const team_recruitment = useRef(null);
    const skills = useRef(null);
    const tests = useRef(null);
    const divisions = useRef(null);
    const predictiveAnalytics = useRef(null);
    const top = useRef(null);
    const [searchParams] = useSearchParams();

    /*const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }*/
    console.log("%c Hey there! Stop snooping around! Instead, why don't you join us?\n https://discord.gg/2Z8Z9Z9", 'color: #ffff; font-size: 20px; font-weight: bold; background-color: rgb(25, 25, 40); border-radius: 10px; padding: 10px; margin-top: 10px;');
    
    useEffect ( () => {
        
        

        switch(searchParams.get("target")) {
            case "predictiveAnalytics":
                predictiveAnalytics.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "divisions":
                divisions.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "tests":
                tests.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            case "skills":
                skills.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "team_recruitment":
                team_recruitment.current?.scrollIntoView({ behavior: 'smooth' });
                break;

            

            default:
                top.current?.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    });

    return (
        <div className="global">
            {//<Navigation/>
            }
            <div className='divider' ref={top}>
                

                <img id="background" src={background} alt=''/>

                <div className="flex-container">
                    <div className="infos">
                        <div className="title">Show <br/> Everyone<br/>
                            <label>Your Style</label>
                        </div>
                        <div className="subtitle">
                            Enjoy the game, showoff, win stuff. That's all we're about, fun and talent. If you're not already convinced, scroll down.
                        </div>
                        <br/> <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/SCvaqxHEUS" style={{
                            textDecoration: 'none'
                        }}> <Button className="button" variant="contained" sx={{ 
                            background: 'linear-gradient(to top, #dbe1ff, #fefeff)',
                            color: '#000',
                            borderRadius: '20px',
                            fontSize: '1.3rem',
                            padding: '0.8rem 1.5rem',
                            fontWeight: 'normal',
                            textTransform: 'none',
                            
                        }}>Join our Discord <OpenInNewIcon sx={{
                            marginLeft: '0.5rem',
                            marginTop: '1px'
                        }} /> </Button></a>
                    </div>
                    <img id="car" src={car} alt=""/>
                </div>
{/*}
                <div className='tournaments'>
                    <div className="group">Entries</div>
                    <div className="top">
                        <div className="tourney">
                            <div className="left">
                                <div className="title">Qualifier W1EV</div>
                                <div className="descriptuion">32 players compete to qualify for this very first edition of the RLST.</div>
                                <div className='content'>
                                    <div className="players">0/32</div>
                                    <div className="duration">Duration: 2 days</div>
                                    <div className="date">Date: 03/11 & 03/12</div>
                                    <div className="prize">Prize: Qualified</div>
                                </div>
                                <div className="button-container bc1">
                                    <div className="rules button"><FormatAlignCenterIcon/> Rules</div>
                                    <div className="modalities button">Modalities</div>
                                </div>
                                <div className="button-container bc2">
                                    <div className="participate button">Participate</div>
                                </div>
                            </div>
                            <div className="sub-rect red-gradient">
                                <img className='ilustration-img' id="illuImgFS" src={carillustration1} alt="" />
                                <div className="sub-rect-text-content">
                                    <label htmlFor="" className="field-title" id="fieldtitle1" >See Bracket</label>
                                    <label htmlFor="" className="field-infos">0 Match Played - 13 Remaining</label>
                                    
                                    <q-btn></q-btn>
                                </div>
                            </div>
                        </div>
                        <div className="tourney">
                            <div className="title"></div>
                            <div className="players"></div>
                            <div className="duration"></div>
                            <div className="date"></div>
                            <div className="prize"></div>

                        </div>
                    </div>
                    
                    <div className="bottom">
                        <div className="tourney">
                            <div className="title"></div>
                            <div className="players"></div>
                            <div className="duration"></div>
                            <div className="date"></div>
                            <div className="prize"></div>
                        </div>
                    </div>
                </div>
*/}
                <div className='tournaments'>
                    <div className="group">Entries</div>
                    <div className="entry-container">
                        <div className="entry w1 cover">
                            <label className="title">Qualifier Week 1</label>
                        </div>
                        <div className="entry w2 hide">
                            <label className="title">Qualifier Week 2</label>
                        </div>
                        <div className="entry final hide">
                            <label className="title">RLST Grand Tournament</label>
                        </div>
                    </div>
                </div>
                <ProgramWave/>
                





{/*

                <div className='cat'>
                    
                    <BlobAnimation style={{marginTop: "100px"}} />
                    <BackgroundWaves />

                    <div className="element" ref={predictiveAnalytics}>
                        <div className="text-container">
                            <label htmlFor="">Predictive Analytics</label>
                            <p>We analyze your skills, replays and datas to get an idea straight away of what would be required for you to progress and how much time it will take. You will develop skills and our algorithm will let you know throughout your journey at Sypher, what you need to improve on and what is your current progression.</p>
                        </div>
                        <img id='illu1' src={illustration1} alt=""/>
                    </div>


                    <div className="element" ref={divisions}>
                        <img id='illu2' src={illustration2} alt=""/>
                        <div className="text-container">
                            <label htmlFor="">Groundbreaking Divisions</label>
                            <p>Divisions. It's nothing really, just some kind of sub-team. You will be placed in a division that fits your current skillset, starting with group project all the way through individual work. You will learn to adapt to teams standards and team-work. Each division include designers, editors and players from sypher userbase for you to build a releasing concept video.</p>
                        </div>            
                    </div>

                    <div className="element" ref={tests}>
                        <div className="text-container">
                            <label htmlFor="">Adaptatives Tests</label>
                            <p>Who doesn't love tests? Throughout your journey at Sypher, you will be asked to work on tiny individual project with guidelines & deadlines to respect. These projects will help us determine your progression and what would be the best for you to improve.</p>
                        </div>
                        <img id='illu3' src={illustration3} alt=""/>
                    </div>
                </div>






                */}

                
{/*
                <div className="global" ref={skills}>
                    <div className="top section">
                        <div className="texts">
                            <label id='subtitle' htmlFor="">20+ programs of training</label>
                            <label htmlFor="" id="title">Learn the best tools <br/>and platforms</label>
                            <p style={{marginBottom: "30px"}}>We focus on Teams standards so that you can be prepared for your next application. Then, we teach all we can about them.</p>
                        </div>
                    </div>

                    <div className="media-scroller">            
                        <div className="rect">
                            <div className="rect-txts">
                                <label htmlFor="" className="feat-skills">Featured Skills</label>
                                <div className="vertical-scroller">
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">1</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Air Control</label>
                                                <label htmlFor="" className="rating">0 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Sustain your height and car control while aiming towards different directions.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">2</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Air roll</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Keep your air car-control while rotating with air-roll keybinds.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">3</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Air dribbling</label>
                                                <label htmlFor="" className="rating">2 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Manage your boost and speed while you're flying all the way through the field!</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">4</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Ceiling shots</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Use the ceiling to keep your flip for an unlimited duration.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">5</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Flip Reset</label>
                                                <label htmlFor="" className="rating">3 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Reset your flip by placing your four wheels under the ball while in the air.</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="sub-rect red-gradient">
                                <img className='ilustration-img' id="illuImgFS" src={carillustration1} alt="" />
                                <div className="sub-rect-text-content">
                                    <label htmlFor="" className="field-title" id="fieldtitle1" >Solo Freestyling</label>
                                    <label htmlFor="" className="field-infos">15 Skills - <span className="material-symbols-outlined">hourglass_bottom</span> Coaches</label>
                                    
                                    <q-btn></q-btn>
                                </div>
                            </div>
                        </div>
                        <div className="rect">
                            <div className="rect-txts">
                                <label htmlFor="" className="feat-skills">Featured Skills</label>
                                <div className="skill-item">
                                    <div className="number"><label htmlFor="">1</label></div>
                                    <div className="skill-infos">
                                        <div className="skill-title">
                                            <label htmlFor="" className='skill-name'>Power Shots</label>
                                            <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                        </div>
                                        <label htmlFor="" className="skill-desc">Hit the ball on the best spot to give it a maximum of power.</label>
                                    </div>

                                </div>
                            </div>
                            <div className="sub-rect yellow-gradient">
                                <img className='ilustration-img' id="illuImgComp" src={carillustration2} alt=""/>
                                <div className="sub-rect-text-content">
                                    <label htmlFor="" className="field-title">Competitive</label>
                                    <label htmlFor="" className="field-infos">15 Skills - <span className="material-symbols-outlined">hourglass_bottom</span> Coaches</label>
                                
                                    <q-btn></q-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <ProgramWave/>
                    <div className="media-scroller" style={{marginTop: "20px"}}>            
                        <div className="rect">
                            <div className="rect-txts">
                        
                                <label htmlFor="" className="feat-skills">Featured Skills</label>
                                <div className="vertical-scroller">
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">1</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Basic Javascript</label>
                                                <label htmlFor="" className="rating">0 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Use variables, loops and basic objects to build standard javascript algorithm.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">2</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Discord Dev Portal</label>
                                                <label htmlFor="" className="rating">0 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Deploy a discord bot using javascript in a few steps.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">3</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Events & Commands</label>
                                                <label htmlFor="" className="rating">1 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Use Event Listeners to program custom commands for your users!</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">4</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Command Handler</label>
                                                <label htmlFor="" className="rating">4 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Have every each of your commands in a seperate file for a better organisation.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">5</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Slash Commands</label>
                                                <label htmlFor="" className="rating">3 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Receive Discord interactions and create custom pre-filled commands.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">6</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Buttons</label>
                                                <label htmlFor="" className="rating">3 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Display custom buttons attached to your bot's messages.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">7</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Database</label>
                                                <label htmlFor="" className="rating">5 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Setup a database with MySQL & Sequelize.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">8</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Table Management</label>
                                                <label htmlFor="" className="rating">5 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Manage your database tables and store user's informations.</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="sub-rect blue-gradient">
                                <img className='ilustration-img' id='ill2' src={discordDev} style={{width: "100%", margin: "0 0 20px 0"}} alt=""/>
                                <div className="sub-rect-text-content">
                                    <label htmlFor="" className="field-title" style={{lineHeight: "25px", marginBottom: "5px"}}>Discord.Js Development</label>
                                    <label htmlFor="" className="field-infos">8 Skills - <span className="material-symbols-outlined">hourglass_bottom</span> Coaches</label>
                                    
                                    <q-btn></q-btn>
                                </div>
                            </div>
                        </div>
                        <div className="rect">
                            <div className="rect-txts">
                                <label htmlFor="" className="feat-skills">Featured Fields</label>
                                <div className="vertical-scroller">
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">1</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Graphic Design</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Create AVI, Headers and thumbnails with Adobe Photoshop.</label>
                                        </div>

                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">2</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Motion Designer</label>
                                                <label htmlFor="" className="rating">10 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Create short videos and animations with Adobe After Effects.</label>
                                        </div>
                                    </div>

                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">3</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Sound Designer</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Create sound effects, often used alongside motion designs. <i>Not available.</i></label>
                                        </div>
                                    </div>

                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">3</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Editor</label>
                                                <label htmlFor="" className="rating">10 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Give a whole new look to your highlights. Record fancy cinematics and use color corrections.</label>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <div className="number"><label htmlFor="">4</label></div>
                                        <div className="skill-infos">
                                            <div className="skill-title">
                                                <label htmlFor="" className='skill-name'>Writter</label>
                                                <label htmlFor="" className="rating">8 <span className="material-symbols-outlined">star</span></label>
                                            </div>
                                            <label htmlFor="" className="skill-desc">Write texts about our structure.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-rect purple-gradient">
                                <img className='ilustration-img' id="ill1" src={mediaIllustration} alt=""/>
                                <div className="sub-rect-text-content">
                                    <label htmlFor="" className="field-title">Creatives</label>
                                    <label htmlFor="" className="field-infos">15 Skills - <span className="material-symbols-outlined">hourglass_bottom</span> Fields</label>
                                    
                                    <q-btn></q-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                

                <div className='ourTeam' ref={team_recruitment}>
                    
                <div className='waveWrapper'>
                    <div className="team-wave1">
                        <svg width="1440" height="699" viewBox="0 0 1440 699" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs><clipPath id="wave1"><path d="M1192 63.1469C979.197 -18.7459 969.021 -3.8326 747.038 15.147C525.055 34.1266 456.426 81.6931 291.038 63.147C125.65 44.6009 38.0215 68.2247 -41.1011 88.0053C-41.1011 175.938 -41.1013 376.556 -41.1013 376.556L1481 376.556L1481 63.1469C1481 63.1469 1405.19 145.19 1192 63.1469Z" fill="url(#paint0_linear)"></path></clipPath></defs>
                        </svg>
                    </div>

                    <div className="team-wave2">
                        <svg width="1440" height="699" viewBox="0 0 1440 699" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs><clipPath id="wave2"><path d="M1221 63.1469C1008.2 -18.7459 998.021 -3.8326 776.038 15.147C554.055 34.1266 485.426 81.6931 320.038 63.147C154.65 44.6009 67.0215 68.2247 -12.1011 88.0053C-12.1011 175.938 -12.1013 376.556 -12.1013 376.556L1510 376.556L1510 63.1469C1510 63.1469 1434.19 145.19 1221 63.1469Z"></path></clipPath></defs>
                        </svg>
                    </div>

                    <div className="team-wave3">
                        <svg width="1440" height="699" viewBox="0 0 1440 699" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <defs><clipPath id="wave3"><path d="M1175.8 62.9028C962.86 -18.9901 952.676 -4.07674 730.548 14.9028C508.419 33.8824 439.745 81.449 274.248 62.9029C108.752 44.3568 21.0657 67.9805 -58.1089 87.7612C-58.1089 175.694 -58.1091 584 -58.1091 584L1464.99 584L1464.99 62.9027C1464.99 62.9027 1389.13 144.946 1175.8 62.9028Z"></path></clipPath></defs>
                        </svg>
                    </div>

                    <StripWave />
                </div>

                    <div className='ourTeam-content'>
                        <div className="top section">
                            <div className="texts">
                                <label id='subtitle' htmlFor="">150+ Development ressources</label>
                                <label htmlFor="" id="title">Help us construct<br/> a new vision.</label>
                                <p style={{marginBottom: "30px"}}>Above it all, Sypher is a Rocket League clip-hitting team. Joining us means bringing your own knowledge and ideas to the development of both our team and platform.</p>
                            </div>

                            <div className="tiles-container">
                                    
                                <div className="tile-team">
                                    <div className="tile-team-item">
                                        <div className="tile-team-item-content-top">
                                            <div className="tile-team-item-content-top-title">
                                                <label htmlFor="" className="tile-team-item-content-top-title-text">Creative</label>
                                            </div>
                                            <div className="tile-team-item-content-top-icon-container">
                                                <svg width={0} height={0}>
                                                    <linearGradient id="linearColors" gradientTransform="rotate(65)" x1={1} y1={0} x2={1} y2={1}>
                                                        <stop offset={0} stopColor="#eb3455" />
                                                        <stop offset={1} stopColor="#5e34eb" />
                                                    </linearGradient>
                                                </svg>
                                                <DesignServicesIcon sx={{ fill: "url(#linearColors)" }} />

                                            </div>
                                        </div>
                                        <div className="tile-team-item-content-desc">
                                            <label htmlFor="" className="tile-team-item-content-desc-text">Create outstanding designs and define standards and improve your own skillset.</label>
                                        </div>
                                        <div className='button-container'>
                                            <Link to="/standards?target=roles" className='tile-team-item-button'>Standards</Link>
                                            <a href="https://discord.gg/SCvaqxHEUS" className='tile-team-item-button marg'>Apply</a>
                                        </div>
                                    </div>
                                    
                                    <div className="tile-team-item">
                                        <div className="tile-team-item-content-top">
                                            <div className="tile-team-item-content-top-title">
                                                <label htmlFor="" className="tile-team-item-content-top-title-text">Player</label>
                                            </div>
                                            <div className="tile-team-item-content-top-icon-container">
                                                <svg width={0} height={0}>
                                                    <linearGradient id="linearColors" gradientTransform="rotate(65)" x1={1} y1={0} x2={1} y2={1}>
                                                        <stop offset={0} stopColor="#eb3455" />
                                                        <stop offset={1} stopColor="#5e34eb" />
                                                    </linearGradient>
                                                </svg>
                                                <SportsSoccerIcon sx={{ fill: "url(#linearColors)" }} />

                                            </div>
                                        </div>
                                        <div className="tile-team-item-content-desc">
                                            <label htmlFor="" className="tile-team-item-content-desc-text">Hit creative and extraordinary clips and appear in our montages, designs and OCE's.</label>
                                        </div>
                                        <div className='button-container'>
                                            <Link to="/standards?target=roles" className='tile-team-item-button'>Standards</Link>
                                            <a href="https://discord.gg/SCvaqxHEUS" className='tile-team-item-button marg'>Apply</a>
                                        </div>
                                    </div>    
                                    
                                </div>
                                <div className="tile-team">
                                    <div className="tile-team-item">
                                        <div className="tile-team-item-content-top">
                                            <div className="tile-team-item-content-top-title">
                                                <label htmlFor="" className="tile-team-item-content-top-title-text">Developer</label>
                                            </div>
                                            <div className="tile-team-item-content-top-icon-container">
                                                <svg width={0} height={0}>
                                                    <linearGradient id="linearColors" gradientTransform="rotate(65)" x1={1} y1={0} x2={1} y2={1}>
                                                        <stop offset={0} stopColor="#eb3455" />
                                                        <stop offset={1} stopColor="#5e34eb" />
                                                    </linearGradient>
                                                </svg>
                                                <ApiIcon sx={{ fill: "url(#linearColors)" }} />

                                            </div>
                                        </div>
                                        <div className="tile-team-item-content-desc">
                                            <label htmlFor="" className="tile-team-item-content-desc-text">Work with Discord.JS API, React, Styled Components & MUI to develop our web app and discord bot.</label>
                                        </div>
                                        <div className='button-container'>
                                            <Link to="/standards?target=roles" className='tile-team-item-button'>Standards</Link>
                                            <a href="https://discord.gg/SCvaqxHEUS" className='tile-team-item-button marg'>Apply</a>
                                        </div>
                                    </div>
                                    
                                    <div className="tile-team-item">
                                        <div className="tile-team-item-content-top">
                                            <div className="tile-team-item-content-top-title">
                                                <label htmlFor="" className="tile-team-item-content-top-title-text">Communication</label>
                                            </div>
                                            <div className="tile-team-item-content-top-icon-container">
                                                <svg width={0} height={0}>
                                                    <linearGradient id="linearColors" gradientTransform="rotate(65)" x1={1} y1={0} x2={1} y2={1}>
                                                        <stop offset={0} stopColor="#eb3455" />
                                                        <stop offset={1} stopColor="#5e34eb" />
                                                    </linearGradient>
                                                </svg>
                                                <HandshakeIcon sx={{ fill: "url(#linearColors)" }} />

                                            </div>
                                        </div>
                                        <div className="tile-team-item-content-desc">
                                            <label htmlFor="" className="tile-team-item-content-desc-text">Communicate our vision and standards by working closely with our teams. Help us manage our public identity.</label>
                                        </div>
                                        <div className='button-container'>
                                            <Link to="/standards?target=roles" className='tile-team-item-button'>Standards</Link>
                                            <a href="https://discord.gg/SCvaqxHEUS" className='tile-team-item-button marg'>Apply</a>
                                        </div>
                                    </div>    
                                    
                                </div>
                            </div>
                        </div>
                        {/*}
                        <div className="sub-section section">
                            <div className="container">
                                <div className="ressources">
                                    <div className='circle1'>
                                        
                                        <label>15+ GFX Packs</label>
                                    </div>
                                    <div className='circle2'>
                                        <label>15+ GFX Packs</label>
                                    </div>
                                    <div className='circle3'>
                                        <label>15+ GFX Packs</label>
                                    </div>
                                </div>
                                
                                <div className="texts">
                                    <label id='subtitle' htmlFor="">150+ Development ressources</label>
                                    <label htmlFor="" id="title">Help us construct<br/> a new vision.</label>
                                    <p style={{marginBottom: "30px"}}>Above it all, Sypher is a Rocket League clip-hitting team. Joining us means bringing your own knowledge and ideas to the development of both our team and platform.</p>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            */}
            </div>
        </div>
    )
}

export default Landing;