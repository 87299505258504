import styled from 'styled-components';
import wave4 from '../../assets/testimonial-wave4.svg';
import wave5 from '../../assets/testimonial-wave5.svg';

export default function ProgramWave() {
    return (
        <div>
            <WaveWrapper>
                <Wave />
                <Wave2 />
            </WaveWrapper>
        </div>
    );
}

const WaveWrapper = styled.div`
position: absolute;
margin-top: -400px;
height: 800px;
padding: 100px 0 0 0;
width: 100%;
overflow: hidden;
pointer-events: none;

@media (max-width: 700px) {
    margin-top: -200px;
    height: 500px;
}

`;


const Wave = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
    margin-top: -180px;
    background-image: url(${wave4});
    mask-image: linear-gradient(180deg, rgba(0,0,0,1) 55%, rgba(255,255,255,0) 90%);
    height: 500px;

    @media (max-width: 700px) {
        height: 700px;
        width: 800px;
        margin-top: -150px;
    }
`;

const Wave2 = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
    margin-top: 20px;
    height: 500px;
    background-image: url(${wave5});
    mask-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 55%);

    @media (max-width: 700px) {
        mask-image: linear-gradient(180deg, rgba(0,0,0,1) 30%, rgba(255,255,255,0) 90%);
        height: 900px;
        width: 1500px;
        margin-top: -10px;
    }
`;
