import React from 'react';
import { useRef, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import './style.scoped.scss';

import background from '../../assets/background_standards.png';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Standards() {
    const roles = useRef(null);
    const receiptPolicy = useRef(null);
    const top = useRef(null);
    const [searchParams] = useSearchParams();

    /*const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }*/

    useEffect ( () => {
        switch(searchParams.get("target")) {
            case "roles":
                roles.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            case "receipt_and_policy":
                receiptPolicy.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            
            default:
                top.current?.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    });

    return (
        <div className='standards' ref={top}>
            
            <img id="background" src={background} alt=''/>

            <div className="flex-container">
                <div className="infos">
                    <div className="title">
                        <label>Recruitment Standards</label>
                    </div>
                    <div className="subtitle">
                        Learn more about your mission in our different fields as a member of Sypher. Fill your application in our discord server now.
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="paragraph">
                    <label className="title">A New Era</label>
                    <p className='text'>Sypher is working toward an inclusive environment where no one feels left behind, where we all learn & share together no matter the skillsets. A team where everyone can find its place, from beginners to veterans. If you'd like to take part in our journey and are comfortable in any of our recruitment roster, come build that new vision of teams with us!</p>
                </div>

                <div className="paragraph">
                    <label className="title" ref={roles}>Roles & Enrollment</label>
                    <p className='text' >Here at Sypher, we value investment, proactivity & ideas. Show the world what you can bring to the table by joining us in any of the available positions. We'd love to work with someone like you.</p>

                    <Accordion style={{
                        margin: "20px 0",
                        background: "rgb(0, 0, 0, 0.1)",
                        color: "#fff",
                        //Slight Shadow from the top to the bottom 
                        
                    }}>
                        <AccordionSummary                       
                        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
                        
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                            <Typography style={{
                                color: "rgba(255, 255, 255, 0.8)",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "130%",
                                textAlign: "left",
                                maxWidth: "800px",
                                letterSpacing: "1pt"

                            }}>Administrative Partner</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            
                            <p className='accordText margin60b'>Sypher is looking for an Administrative Partner to work with our different Directors. We are looking for someone who is passionate about our mission.</p>

                            <label className="accordTitle ">What You'll Be Doing</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Collaborate with the development team to ensure we are on track to meet the deadlines. Assign tasks to members with timely follow-up.</label></li>
                                <li><label className="listItem"><i>•</i> Schedule external and internal meetings via Discord.</label></li>
                                <li><label className="listItem"><i>•</i> Manage the team's communication and communication channels.</label></li>
                                <li><label className="listItem"><i>•</i> Collaborate closely with other admins to make sure the leadership team is operating smoothly.</label></li>
                                <li><label className="listItem"><i>•</i> Act as a booster and be proactive throughout the development, mentoring new members, assisting leadership and follow through.</label></li>
                                <li><label className="listItem"><i>•</i> Work proactively and feedback on others work.</label></li>
                            </ul>

                            <label className="accordTitle ">In This Role You'll Thrive If..</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> High level of proactivity and investment.</label></li>
                                <li><label className="listItem"><i>•</i> 6+ month experience within Rocket League Team Community.</label></li>
                                <li><label className="listItem"><i>•</i> Extraordinary communication skills, both written and verbal.</label></li>
                                <li><label className="listItem"><i>•</i> Project management skills - being able to see as a whole even in details.</label></li>
                                <li><label className="listItem"><i>•</i> Critical thinking to help leadership manage Sypher.</label></li>

                            </ul>

                            <label className="accordTitle ">Bonus Points</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Prior experience in a leading role, particularly within personnal public project (teams, orgs, groups...).</label></li>
                                <li><label className="listItem"><i>•</i> Experience with Discord server management.</label></li>
                                <li><label className="listItem"><i>•</i> Experience with Adobe© Photoshop©.</label></li>
                                <li><label className="listItem"><i>•</i> Writing skills.</label></li>
                                <li><label className="listItem"><i>•</i> Soft skills.</label></li>
                            </ul>

                            <label className="accordTitle ">Benefits and Perks</label>
                            <ul className='accordList margin20t'>
                                <li><label className="listItem"><i>•</i> Personal 1000²px Sypher AVI designed by our designers.</label></li>
                                <li><label className="listItem"><i>•</i> Sypher GFX's packages and ressources.</label></li>
                                <li><label className="listItem"><i>•</i> Free access to the platform in closed previews.</label></li>
                                <li><label className="listItem"><i>•</i> Access to membership giveaways.</label></li>
                                <li><label className="listItem"><i>•</i> Cookies. These will probably turn out to be gifs but hey still kinda cool.</label></li>
                            </ul>
                            
                            <p className='accordText margin60t'>Come & build belonging with us! Apply now in our <a href="https://discord.gg/SCvaqxHEUS">discord server</a>.</p>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{
                        margin: "0px 0 20px 0",
                        background: "rgb(0, 0, 0, 0.1)",
                        color: "#fff",
                        //Slight Shadow from the top to the bottom 
                        
                    }}>
                        <AccordionSummary                       
                        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
                        
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                            <Typography style={{
                                color: "rgba(255, 255, 255, 0.8)",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "130%",
                                textAlign: "left",
                                maxWidth: "800px",
                                letterSpacing: "1pt"

                            }}>Head Of Communication</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            
                            <p className='accordText margin60b'>Sypher is looking for an Head Of Communication. You will be responsible for Sypher's public identity & communication process.</p>

                            <label className="accordTitle ">What You'll Be Doing</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Collaborate with the leadership and other managers.</label></li>
                                <li><label className="listItem"><i>•</i> Schedule meetings with your team and assign tasks to your members with timely follow-up.</label></li>
                                <li><label className="listItem"><i>•</i> Set standards for our communication and design team.</label></li>
                                <li><label className="listItem"><i>•</i> Think of new way of promoting Sypher.</label></li>
                            </ul>

                            <label className="accordTitle ">In This Role You'll Thrive If..</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> High level of proactivity and investment.</label></li>
                                <li><label className="listItem"><i>•</i> 1+ year experience within Rocket League Team Community.</label></li>
                                <li><label className="listItem"><i>•</i> Extraordinary communication skills, both written and verbal.</label></li>
                                <li><label className="listItem"><i>•</i> Team management skills - schedule meetings, assign tasks, respect deadlines...</label></li>
                                <li><label className="listItem"><i>•</i> Critical thinking to assist our development.</label></li>

                            </ul>

                            <label className="accordTitle ">Bonus Points</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Experience with Discord server management.</label></li>
                                <li><label className="listItem"><i>•</i> 6+ month experience in team management.</label></li>
                                <li><label className="listItem"><i>•</i> Writing skills.</label></li>
                                <li><label className="listItem"><i>•</i> Soft skills.</label></li>
                            </ul>

                            <label className="accordTitle ">Benefits and Perks</label>
                            <ul className='accordList margin20t'>
                                <li><label className="listItem"><i>•</i> Personal 1000²px Sypher AVI designed by our designers.</label></li>
                                <li><label className="listItem"><i>•</i> Free access to the platform in closed previews.</label></li>
                                <li><label className="listItem"><i>•</i> Access to membership giveaways.</label></li>
                                <li><label className="listItem"><i>•</i> Advertising in our distribution.</label></li>
                            </ul>
                            
                            <p className='accordText margin60t'>Come & build belonging with us! Apply now in our <a href="https://discord.gg/SCvaqxHEUS">discord server</a>.</p>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{
                        margin: "0px 0 20px 0",
                        background: "rgb(0, 0, 0, 0.1)",
                        color: "#fff",
                        //Slight Shadow from the top to the bottom 
                        
                    }}>
                        <AccordionSummary                       
                        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
                        
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                            <Typography style={{
                                color: "rgba(255, 255, 255, 0.8)",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "130%",
                                textAlign: "left",
                                maxWidth: "800px",
                                letterSpacing: "1pt"

                            }}>Design Manager</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            
                            <p className='accordText margin60b'>As our Designer Manager, you will be involved in the full product development process from end-to-end. You will use a balance of interaction, visual and design skills within our design team that you will end up building. If you are interested in joining, leading and building your own design team, come help us do that.</p>

                            <label className="accordTitle ">What You'll Be Doing</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Collaborate with the Communication team to ensure and build our visual identity throughout our development.</label></li>
                                <li><label className="listItem"><i>•</i> Take recruitment decision and review designer application with your recruiter.</label></li>
                                <li><label className="listItem"><i>•</i> Create great-looking, astonishing and inovating designs.</label></li>
                            </ul>

                            <label className="accordTitle ">In This Role You'll Thrive If..</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> 2+ month experience within Rocket League Team Community.</label></li>
                                <li><label className="listItem"><i>•</i> 3+ years experience with Adobe Photoshop.</label></li>
                                <li><label className="listItem"><i>•</i> 2+ years experience with Adobe Illustrator.</label></li>
                                <li><label className="listItem"><i>•</i> 1+ year experience with Design Rules & Standards.</label></li>
                                <li><label className="listItem"><i>•</i> Team management skills - schedule meetings, assign tasks, respect deadlines...</label></li>
                            </ul>

                            <label className="accordTitle ">Bonus Points</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> 6+ month experience in team management.</label></li>
                            </ul>

                            <label className="accordTitle ">Benefits and Perks</label>
                            <ul className='accordList margin20t'>
                                <li><label className="listItem"><i>•</i> Personal 1000²px Sypher AVI designed by our designers.</label></li>
                                <li><label className="listItem"><i>•</i> Free access to the platform in closed previews.</label></li>
                                <li><label className="listItem"><i>•</i> Access to membership giveaways.</label></li>
                                <li><label className="listItem"><i>•</i> Sypher GFX's packages and ressources.</label></li>
                                <li><label className="listItem"><i>•</i> Advertising in our distribution.</label></li>
                            </ul>
                            
                            <p className='accordText margin60t'>Come & build belonging with us! Apply now in our <a href="https://discord.gg/SCvaqxHEUS">discord server</a>.</p>

                        </AccordionDetails>
                    </Accordion>


                    <Accordion style={{
                        margin: "0px 0 20px 0",
                        background: "rgb(0, 0, 0, 0.1)",
                        color: "#fff",
                        //Slight Shadow from the top to the bottom 
                        
                    }}>
                        <AccordionSummary                       
                        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
                        
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                            <Typography style={{
                                color: "rgba(255, 255, 255, 0.8)",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "130%",
                                textAlign: "left",
                                maxWidth: "800px",
                                letterSpacing: "1pt"

                            }}>Recruiter</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            
                            <p className='accordText margin60b'>As a Recruiter, you will be involved in the recruitment of new members within one of our teams. Recruiters make sure that our teams are filled with the best in the community. They go through applications, review them and decide if they are a good fit for the team. <b>You must apply for Designer, Player, Editor, or Communication member before applying for a Recruiter position.</b></p> 

                            <label className="accordTitle ">What You'll Be Doing</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Take recruitment decisions and review applications corresponding to your main position (Designer, Player, Editor...). You must understand our standards and vision to ensure they're being applied throughout our recruitment process.</label></li>
                            </ul>

                            <label className="accordTitle ">In This Role You'll Thrive If..</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> 2+ month experience within Rocket League Team Community.</label></li>
                                <li><label className="listItem"><i>•</i> Extraordinary skills in your main position.</label></li>
                            </ul>

                            <label className="accordTitle ">Bonus Points</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Coaching skills in your main position.</label></li>
                                <li><label className="listItem"><i>•</i> 3+ previous experiences as a Recruiter (Usually called "Lead FS", "Lead Designer", etc.).</label></li>
                                <li><label className="listItem"><i>•</i> Deep understanding of sarcasm.</label></li>
                            </ul>

                            <label className="accordTitle ">Benefits and Perks</label>
                            <ul className='accordList margin20t'>
                                <li><label className="listItem"><i>•</i> Advertising in our distribution.</label></li>
                            </ul>
                            
                            <p className='accordText margin60t'>Come & build belonging with us! Apply now in our <a href="https://discord.gg/SCvaqxHEUS">discord server</a>.</p>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{
                        margin: "0px 0 20px 0",
                        background: "rgb(0, 0, 0, 0.1)",
                        color: "#fff",
                        //Slight Shadow from the top to the bottom 
                        
                    }}>
                        <AccordionSummary                       
                        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
                        
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                            <Typography style={{
                                color: "rgba(255, 255, 255, 0.8)",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "130%",
                                textAlign: "left",
                                maxWidth: "800px",
                                letterSpacing: "1pt"

                            }}>Player</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            
                            <p className='accordText margin60b'>As a Player specialized in freestyle, competitive clip-hit or no-flip, you will send clips to your executive which will be used in our edits & videos. If you got what it takes, give it a shot and give yourself a chance appearing in our videos. <b>Clips may also be used for promotional content.</b></p>

                            <label className="accordTitle ">What You'll Be Doing</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Score specific and generic clips in game.</label></li>
                                <li><label className="listItem"><i>•</i> Represent our team as a human being.</label></li>
                            </ul>

                            <label className="accordTitle ">In This Role You'll Thrive If..</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Currently ranked in <b>high</b> Grand Champion 2 or above (Competitive Clip-Hit).</label></li>
                                <li><label className="listItem"><i>•</i> Smooth air-control & mechanics management. For instance going from a reset musty double to a backboard reset. Be creative.</label></li>
                                <li><label className="listItem"><i>•</i> You will learn more about our expectations through our application process. Give it a shot if you feel like you've got what it takes!</label></li>
                            </ul>

                            <label className="accordTitle ">Bonus Points</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Creative, uncommon or even unseen clips.</label></li>
                            </ul>

                            <label className="accordTitle ">Benefits and Perks</label>
                            <ul className='accordList margin20t'>
                                <li><label className="listItem"><i>•</i> Personal 1000²px Sypher AVI designed by our designers.</label></li>
                                <li><label className="listItem"><i>•</i> Free access to the platform in closed previews.</label></li>
                                <li><label className="listItem"><i>•</i> Access to membership giveaways.</label></li>
                            </ul>
                            
                            <p className='accordText margin60t'>Come & build belonging with us! Apply now in our <a href="https://discord.gg/SCvaqxHEUS">discord server</a>.</p>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{
                        margin: "0px 0 20px 0",
                        background: "rgb(0, 0, 0, 0.1)",
                        color: "#fff",
                        //Slight Shadow from the top to the bottom 
                        
                    }}>
                        <AccordionSummary                       
                        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
                        
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                            <Typography style={{
                                color: "rgba(255, 255, 255, 0.8)",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "130%",
                                textAlign: "left",
                                maxWidth: "800px",
                                letterSpacing: "1pt"

                            }}>Creative</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            
                            <p className='accordText margin60b'>As a Creative, you'll be involved in the creation process of our identity throughout our development and communication. You will be creative amazing designs, animations or edits to represent Sypher in its best way by transcribing our vision and goals.</p>

                            <label className="accordTitle ">What You'll Be Doing</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Create great-looking, astonishing and inovating designs.</label></li>
                                <li><label className="listItem"><i>•</i> Collaborate closely with your team and executive to ensure the designs fit the requirements.</label></li>
                                <li><label className="listItem"><i>•</i> Establish and communicate visuals and standards across Sypher's entire brand.</label></li>
                                <li><label className="listItem"><i>•</i> Meet deadlines and have the ability to adapt quickly to changing proirities.</label></li>
                                <li><label className="listItem"><i>•</i> Work mostly autonomosly and feedback on others work.</label></li>
                            </ul>

                            <label className="accordTitle ">What you should have</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> A portfolio showcasing relevant work.</label></li>
                                <li><label className="listItem"><i>•</i> 1+ year of experience as a Designer.</label></li>
                                <li><label className="listItem"><i>•</i> Can provide multiple creative solutions to problems.</label></li>
                                <li><label className="listItem"><i>•</i> Designer: Solid Photoshop & vector illustration skills.</label></li>
                                <li><label className="listItem"><i>•</i> Motion-Designer: Solid After Effect & Photoshop skills.</label></li>
                                <li><label className="listItem"><i>•</i> Editor: Solid After Effect & C4D skills.</label></li>
                                <li><label className="listItem"><i>•</i> Strong fundamental design skills, including knowledge of typography, composition, and color.</label></li>
                                <li><label className="listItem"><i>•</i> Up-to-date with current design technologies and trends.</label></li>
                            </ul>

                            <label className="accordTitle ">Bonus Points</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Working knowledge in web standard technologies Javascript, HTML and CSS for browsers and mobile devices.</label></li>
                                <li><label className="listItem"><i>•</i> Working knowledge in C4D.</label></li>
                                <li><label className="listItem"><i>•</i> Brand guideline creation experience.</label></li>
                            </ul>

                            <label className="accordTitle ">Benefits and Perks</label>
                            <ul className='accordList margin20t'>
                                <li><label className="listItem"><i>•</i> Personal 1000²px Sypher AVI designed by our designers.</label></li>
                                <li><label className="listItem"><i>•</i> Free access to the platform in closed previews.</label></li>
                                <li><label className="listItem"><i>•</i> Access to membership giveaways.</label></li>
                                <li><label className="listItem"><i>•</i> Sypher GFX's packages and ressources.</label></li>
                            </ul>
                            
                            <p className='accordText margin60t'>Come & build belonging with us! Apply now in our <a href="https://discord.gg/SCvaqxHEUS">discord server</a>.</p>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{
                        margin: "0px 0 20px 0",
                        background: "rgb(0, 0, 0, 0.1)",
                        color: "#fff",
                        //Slight Shadow from the top to the bottom 
                        
                    }}>
                        <AccordionSummary                       
                        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
                        
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                            <Typography style={{
                                color: "rgba(255, 255, 255, 0.8)",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "130%",
                                textAlign: "left",
                                maxWidth: "800px",
                                letterSpacing: "1pt"

                            }}>Developer</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            
                            <p className='accordText margin60b'>As a developer you will be working on our webapp project and discord development through React and the DiscordJS API.</p>

                            <label className="accordTitle ">What You'll Be Doing</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Develop NodeJS applications and web projects.</label></li>
                                <li><label className="listItem"><i>•</i> Collaborate closely with your team and executives to ensure the product fits the requirements.</label></li>
                                <li><label className="listItem"><i>•</i> Establish a product and concept platform that will be used by our community.</label></li>
                                <li><label className="listItem"><i>•</i> Meet deadlines and have the ability to adapt quickly to changing proirities.</label></li>
                                <li><label className="listItem"><i>•</i> Work mostly autonomosly and feedback on others work.</label></li>
                            </ul>

                            <label className="accordTitle ">What you should have</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> A portfolio showcasing relevant work.</label></li>
                                <li><label className="listItem"><i>•</i> Some advanced experience with NodeJS & React.</label></li>
                                <li><label className="listItem"><i>•</i> Can provide multiple creative solutions to problems.</label></li>
                                <li><label className="listItem"><i>•</i> Strong fundamental development skills, including knowledge of Git, NodeJS, MySQL, React, HTML, CSS/SCSS and Javascript.</label></li>
                                <li><label className="listItem"><i>•</i> Up-to-date with current development technologies and trends.</label></li>
                            </ul>

                            <label className="accordTitle ">Benefits and Perks</label>
                            <ul className='accordList margin20t'>
                                <li><label className="listItem"><i>•</i> Personal 1000²px Sypher AVI designed by our designers.</label></li>
                                <li><label className="listItem"><i>•</i> Free access to the platform in closed previews.</label></li>
                                <li><label className="listItem"><i>•</i> Access to membership giveaways.</label></li>
                                <li><label className="listItem"><i>•</i> Sypher GFX's development packages and ressources.</label></li>
                            </ul>
                            
                            <p className='accordText margin60t'>Come & build belonging with us! Apply now in our <a href="https://discord.gg/SCvaqxHEUS">discord server</a>.</p>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{
                        margin: "0px 0 20px 0",
                        background: "rgb(0, 0, 0, 0.1)",
                        color: "#fff",
                        //Slight Shadow from the top to the bottom 
                        
                    }}>
                        <AccordionSummary                       
                        expandIcon={<ExpandMoreIcon style={{color:"white"}}/>}
                        
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        >
                            <Typography style={{
                                color: "rgba(255, 255, 255, 0.8)",
                                fontWeight: "bold",
                                fontSize: "20px",
                                lineHeight: "130%",
                                textAlign: "left",
                                maxWidth: "800px",
                                letterSpacing: "1pt"

                            }}>Communication</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            
                            <p className='accordText margin60b'>Sypher is looking for Communication Members. You will be making sure Sypher's public identity is respected and fits the current standards.</p>

                            <label className="accordTitle ">What You'll Be Doing</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Collaborate with the Head Of Communication in taking decision regarding Sypher's communication.</label></li>
                                <li><label className="listItem"><i>•</i> Participate to scheduled meeting with your team.</label></li>
                                <li><label className="listItem"><i>•</i> Bring your own vision of what Teams should be. Suggesting ideas and taking initiative. Add your own stone to what we build.</label></li>
                                <li><label className="listItem"><i>•</i> Promote our different platforms and medias. Think of new way of promoting Sypher.</label></li>
                            </ul>

                            <label className="accordTitle ">In This Role You'll Thrive If..</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> High level of proactivity and investment.</label></li>
                                <li><label className="listItem"><i>•</i> 1+ year experience within Rocket League Team Community.</label></li>
                                <li><label className="listItem"><i>•</i> Communication skills, both written and verbal.</label></li>
                                <li><label className="listItem"><i>•</i> Ideas of things you thought could be better but never could apply because your team did not listen.</label></li>
                                <li><label className="listItem"><i>•</i> Critical thinking to assist your executive manager.</label></li>

                            </ul>

                            <label className="accordTitle ">Bonus Points</label>
                            <ul className='accordList margin20t margin60b'>
                                <li><label className="listItem"><i>•</i> Experience with Discord server management.</label></li>
                                <li><label className="listItem"><i>•</i> Writing skills.</label></li>
                                <li><label className="listItem"><i>•</i> Soft skills.</label></li>
                            </ul>

                            <label className="accordTitle ">Benefits and Perks</label>
                            <ul className='accordList margin20t'>
                                <li><label className="listItem"><i>•</i> Personal 1000²px Sypher AVI designed by our designers.</label></li>
                                <li><label className="listItem"><i>•</i> Free access to the platform in closed previews.</label></li>
                                <li><label className="listItem"><i>•</i> Access to membership giveaways.</label></li>
                                <li><label className="listItem"><i>•</i> Other things you'll find out with us!</label></li>
                            </ul>
                            
                            <p className='accordText margin60t'>Come & build belonging with us! Apply now in our <a href="https://discord.gg/SCvaqxHEUS">discord server</a>.</p>

                        </AccordionDetails>
                    </Accordion>
{/*                 
                    <ul className='list'>                      
                        <li><label> • Head Of Communication</label></li>
                        <li><label> • Illustrator</label></li>
                        <li><label> • Administrative Partner</label></li>
                    </ul>
*/}

                </div>

                <div className="paragraph">
                    <label className="title" ref={receiptPolicy}>Member Receipt & Policy</label>
                    <p className='text'> By joining Sypher, you hereby acknowledge that you are at least 15 years old and that you have read and understood our <Link to="/handbook">Member Handbook</Link>. Also understand that we do not provide any employment contract or any other kind of employment agreement.<br/> 
                    <br/>
                    Please also note that our roster is hidden and only visible to our members. Which means you should not disclose any information about your application to anyone.<br/>
                    <br/>
                    Any information a member learns about Sypher or its members or partners as a result of managing Sypher that is not otherwise disclosed is strictly confidential. Members may not disclose confidential information to anyone who is not another member or management member by Sypher. The disclosure, distribution, or copying of Sypher's confidential information is strictly prohibited. Any staff member who uses his or her status to disclose any confidential Sypher information will be subject to disciplinary action, which includes demotion and a possible ban from Sypher, depending on the severity of the infringement. <br/>
                    <br/>
                    It is pivotal that you understand the above policy and pledge to not disclose confidential information.  </p>
                </div>
            </div>
        </div>
    )
}



export default Standards;